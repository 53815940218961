import React from "react";
import { styled } from "@mui/material/styles";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "@/layout";
import { Hero } from "@/components/Hero";
import { PrimaryButton } from "@/components/buttons";
import { SEO } from "@/layout/SEO";
import { Section } from "@/components/section";

const PREFIX = "ThankYouPage";

const classes = {
  hero: `${PREFIX}-hero`,
  section: `${PREFIX}-section`,
  downloadContainer: `${PREFIX}-downloadContainer`,
  downloadContent: `${PREFIX}-downloadContent`,
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
  primaryText: `${PREFIX}-primaryText`,
  ctaArea: `${PREFIX}-ctaArea`,
};

const ThankYouPageStyled = styled("div")(({ theme }) => ({
  [`& .${classes.hero}`]: {
    marginTop: 60,
  },

  [`& .${classes.section}`]: {
    paddingTop: 0,
    paddingBottom: "5em",
  },

  [`& .${classes.downloadContainer}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.downloadContent}`]: {
    border: "1px solid lightgray",
    borderRadius: 4,
    boxShadow: "rgb(218, 218, 218) 0px 0px 13px 0px",
    maxWidth: 500,
    padding: "2em",
    textAlign: "center",
    width: "100%",
  },

  [`& .${classes.imageContainer}`]: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto 2em",
  },

  [`& .${classes.image}`]: {
    borderRadius: 4,
  },

  [`& .${classes.primaryText}`]: {
    fontSize: "20px",
    fontWeight: 800,
    margin: "0 0 .5em",
  },

  [`& .${classes.ctaArea}`]: {},
}));

export interface ThankYouPageProps {
  data: any;
  location: {
    pathname: string;
  };
}

const ThankYouPage = ({ data }: ThankYouPageProps) => {
  const {
    callToAction,
    description,
    guideLocation,
    primaryText,
    screenshot,
    title,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout lightBackground useScroll footerCta={false}>
      <ThankYouPageStyled>
        <Hero
          className={classes.hero}
          color="gray12"
          headline={title}
          text={description}
        />
        <Section className={classes.section}>
          <div className={classes.downloadContainer}>
            <div className={classes.downloadContent}>
              <div className={classes.imageContainer}>
                <GatsbyImage
                  image={screenshot.childImageSharp.gatsbyImageData}
                  alt={primaryText}
                  className={classes.image}
                />
              </div>
              <div>
                <div className={classes.primaryText}>{primaryText}</div>
                <div className={classes.ctaArea}>
                  <PrimaryButton
                    component="a"
                    href={guideLocation}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="large"
                    download={true}
                  >
                    {callToAction.text}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </ThankYouPageStyled>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents
      frontmatter {
        ...SeoFields
        primaryText
        guideLocation
        callToAction {
          text
        }
        screenshot {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              quality: 85
              width: 650
            )
          }
        }
      }
    }
  }
`;

export const Head = ({ location, data }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.markdownRemark.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};

export default ThankYouPage;
